import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import reduce from "lodash/reduce";
import startCase from "lodash/startCase";

import { accountStates, dateFilters, fieldTypes } from "@evolved/constants";

import { long } from "utils/format-date";
import { AccountsSet } from "./accounts-set";
import { ActivityTypesSet } from "./activity-types-set";
import { ContactsSet } from "./contacts-set";
import { LossReasonsSet } from "./loss-reasons-set";
import { OpportunitiesSet } from "./opportunities-set";
import { OpportunityStatesSet } from "./opportunity-states-set";
import { ProductsSet } from "./products-set";
import { SalesProcessesSet } from "./sales-processes-set";
import { TagsSet } from "./tags-set";
import { UsersSet } from "./users-set";
import { VendorsSet } from "./vendors-set";

const {
  ACCOUNT_STATE,
  ACTIVITY_DATE,
  CALCULATED,
  DATE,
  DOLLAR,
  FOLLOWUP_DATE,
  NUMBER,
  PERCENT,
  SELECT,
  SET,
  TEXT,
} = fieldTypes;

const componentsMap = {
  [ACCOUNT_STATE]: ({ value }) => value.map(accountStates.display).join(", "),
  [ACTIVITY_DATE]: ({ value }) => {
    if (isArray(value)) {
      return `${long(value[0])} - ${long(value[1])}`;
    }

    if (dateFilters.OPTIONS[value]) {
      return dateFilters.OPTIONS[value];
    }

    const [tense, quantity, unit] = value.split("_");

    return `${startCase(tense.toLowerCase())} ${quantity} ${startCase(unit.toLowerCase())}${quantity > 1 ? "s" : ""}`;
  },
  [CALCULATED]: ({ value: { max, min } }) => {
    if (!isNil(max) && !isNil(min)) return `Between ${min} and ${max}`;
    if (isNil(max) && !isNil(min)) return `Minimum ${min}`;
    if (isNil(min) && !isNil(max)) return `Maximum ${max}`;

    return "";
  },
  [DATE]: ({ value }) => {
    if (isArray(value)) {
      return `${long(value[0])} - ${long(value[1])}`;
    }

    if (dateFilters.OPTIONS[value]) {
      return dateFilters.OPTIONS[value];
    }

    const [tense, year, month] = value.split("_");

    if (tense === "FUTUREYM") {
      return `${startCase(month.toLowerCase())}, ${year}`;
    }

    return `${startCase(tense.toLowerCase())} ${year} ${startCase(month.toLowerCase())}${year > 1 ? "s" : ""}`;
  },
  [DOLLAR]: ({ value: { max, min } }) => {
    if (!isNil(max) && !isNil(min)) return `Between $${min} and $${max}`;
    if (isNil(max) && !isNil(min)) return `Minimum $${min}`;
    if (isNil(min) && !isNil(max)) return `Maximum $${max}`;

    return "";
  },
  [FOLLOWUP_DATE]: ({ value }) => {
    if (isArray(value)) {
      return `${long(value[0])} - ${long(value[1])}`;
    }

    if (dateFilters.OPTIONS[value]) {
      return dateFilters.OPTIONS[value];
    }

    const [tense, year, month] = value.split("_");

    if (tense === "FUTUREYM") {
      return `${startCase(month.toLowerCase())}, ${year}`;
    }

    return `${startCase(tense.toLowerCase())} ${year} ${startCase(month.toLowerCase())}${year > 1 ? "s" : ""}`;
  },
  [NUMBER]: ({ value: { max, min } }) => {
    if (!isNil(max) && !isNil(min)) return `Between ${min} and ${max}`;
    if (isNil(max) && !isNil(min)) return `Minimum ${min}`;
    if (isNil(min) && !isNil(max)) return `Maximum ${max}`;

    return "";
  },
  [PERCENT]: ({ value: { max, min } }) => {
    if (!isNil(max) && !isNil(min)) return `Between ${min}% and ${max}%`;
    if (isNil(max) && !isNil(min)) return `Minimum ${min}%`;
    if (isNil(min) && !isNil(max)) return `Maximum ${max}%`;

    return "";
  },
  [SELECT]: ({ value }, config) => {
    const { options } = config;

    return reduce(
      value,
      (accumulator, id) => {
        const found = find(options, (o) => o.id === id && !o.isArchived);

        return found ? [...accumulator, found.label] : accumulator;
      },
      []
    ).join(", ");
  },
  [SET]: ({ value }, config) => {
    if (!isArray(value)) {
      value = [value];
    }

    if (isEmpty(value)) {
      return "-";
    }

    const Component = {
      accounts: AccountsSet,
      activityTypes: ActivityTypesSet,
      contacts: ContactsSet,
      lossReasons: LossReasonsSet,
      opportunities: OpportunitiesSet,
      opportunityStates: OpportunityStatesSet,
      products: ProductsSet,
      salesProcesses: SalesProcessesSet,
      tags: TagsSet,
      users: UsersSet,
      vendors: VendorsSet,
    }[config.collection];

    if (!Component) {
      return "-";
    }

    return <Component value={value} />;
  },
  [TEXT]: ({ value }) => value.join(", "),
};

export const renderView = ({ config, filter }) => {
  return componentsMap[config.type](filter, config);
};
