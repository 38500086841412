import compact from "lodash/compact";
import filter from "lodash/filter";
import find from "lodash/find";
import flatten from "lodash/flatten";
import get from "lodash/get";
import indexOf from "lodash/indexOf";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import map from "lodash/map";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import day from "dayjs";
import {
  ClockCircleOutlined,
  InfoCircleOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  DatePicker,
  Empty,
  List,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { usePrevious } from "react-use";

import { colors, styles } from "@evolved/constants";

import { detailed } from "utils/format-date";
import { Metadata } from "../lists/metadata";
import { FullEmails } from "./full-emails";
import { RangeFilter } from "./range-filter";
import { useContacts } from "../../data/use-contacts";
import { useUsers } from "data/use-users";
import { useMe } from "data/use-me";

import classes from "./style.module.css";

const { RangePicker } = DatePicker;

const { PRIMARY } = colors;
const { PRIMARY_TEXT } = styles;

const getDefaultRange = () => [
  day().subtract(14, "days").startOf("day"),
  day().endOf("day"),
];

// todo: test

const Header = ({ emails, profile, range, refresh, setRange, users }) => {
  const filteredUsers = users.filter((user) => {
    return (
      get(user, "nylas.isConfigured") &&
      (profile._id === user._id || get(user, "nylas.settings.isEmailPublic"))
    );
  });

  return (
    <div style={{ alignItems: "center", display: "flex", width: "100%" }}>
      <Badge
        count={emails.length}
        showZero
        style={{ backgroundColor: PRIMARY, marginRight: "16px" }}
      />
      <div style={{ alignItems: "center", display: "flex", lineHeight: "1" }}>
        <h3 style={{ margin: "0px 8px 2px 0px" }}>Email Stream</h3>
        <Tooltip
          title={
            <div>
              Searching across integrated email(s)...
              <br />
              <br />
              {filteredUsers.map((user) => user?.nylas?.email).join(", ")}
            </div>
          }
        >
          <InfoCircleOutlined style={{ marginRight: "8px" }} />
        </Tooltip>
      </div>

      <div style={{ marginLeft: "auto" }}>
        <RangePicker
          allowClear={false}
          disabledDate={(current) => {
            return current && current > day().endOf("day");
          }}
          placeholder={["Start Time", "End Time"]}
          onChange={(range) => {
            setRange(range);
            refresh();
          }}
          value={range}
        />
      </div>
      <div style={{ marginLeft: "16px" }}>
        <RangeFilter
          setRange={(value) => {
            setRange(value);
            refresh();
          }}
        />
      </div>
      <Button
        ghost
        icon={<SyncOutlined />}
        onClick={() => {
          refresh();
        }}
        shape="circle"
        style={{ marginLeft: "16px" }}
        type="primary"
      />
    </div>
  );
};

export const Persons = ({ label, persons = [] }) => {
  if (isEmpty(persons)) {
    return null;
  }

  return (
    <div>
      <strong>{label}</strong> {persons.join(", ")}
    </div>
  );
};

const Email = (props) => {
  const { email, onClick, onLogActivity } = props;
  const { bcc, cc, created, from, snippet, subject, to, user } = email;

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "8px",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginBottom: "8px",
            }}
          >
            <Tag>
              <ClockCircleOutlined style={{ marginRight: "8px" }} />
              {detailed(created)}
            </Tag>
            <Metadata
              Icon={UserOutlined}
              style={{ marginLeft: "8px", marginRight: "0px" }}
              value={user.alias}
            />
            <Button
              ghost
              onClick={() => onLogActivity && onLogActivity(email)}
              type="primary"
              style={{ marginLeft: "auto" }}
            >
              Log Activity
            </Button>
            <Button
              ghost
              onClick={onClick}
              type="primary"
              style={{ marginLeft: "8px" }}
            >
              View
            </Button>
          </div>
          <div>
            <strong>From</strong> {from}
          </div>
          <Persons label="To" persons={to} />
          <Persons label="cc" persons={cc} />
          <Persons label="bcc" persons={bcc} />
          <div>
            <strong>Subject</strong> {subject}
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <span style={{ ...PRIMARY_TEXT }}>{snippet}</span>
      </div>
    </>
  );
};

const getParams = ({ contacts, page, range, user }) => {
  const params = new URLSearchParams();

  if (page) params.append("page", page);

  const start = range[0].startOf("day").toISOString();
  const end = range[1].endOf("day").toISOString();

  params.append("start", start);
  params.append("end", end);

  params.append(
    "emails",
    contacts
      .filter(({ email }) => !isEmpty(email))
      .map(({ email }) => email)
      .join(",")
  );
  params.append("user", user._id);

  return params.toString();
};

const isSame = (a, b) => {
  return (
    a &&
    b &&
    a.length === b.length &&
    a.every(({ _id }) => b.some((second) => _id === second._id))
  );
};

export const Emails = (props) => {
  const { getToken } = useAuth();

  const users = useUsers().all();
  const profile = useMe();

  const contacts = useContacts().byIds(props.contacts);
  const previousContacts = usePrevious(contacts);
  const previousUsers = usePrevious(users);

  const [isLoading, setIsLoading] = useState(false);
  const [cursors, setCursors] = useState([]);
  const [emails, setEmails] = useState();
  const [emailIndex, setEmailIndex] = useState(-1);
  const [range, setRange] = useState(getDefaultRange());

  const onLogActivity = (email) =>
    props.onLogActivity &&
    props.onLogActivity(
      email,
      cursors.map(({ userEmail }) => userEmail)
    );

  const search = async () => {
    setIsLoading(true);

    let updatedCursors = await Promise.all(
      map(users, async (user) => {
        let cursor = find(cursors, { userId: user._id });

        if (cursor && !cursor.nextPage) {
          return;
        }

        if (
          !get(user, "integrations.nylas.isConfigured") ||
          (profile._id !== user._id &&
            !get(user, "integrations.nylas.settings.isEmailPublic"))
        ) {
          return;
        }

        const params = getParams({
          contacts,
          page: get(cursor, "nextPage"),
          range,
          user,
        });

        try {
          const response = await fetch(
            `${import.meta.env.VITE_API_URL
            }/integrations/nylas/emails?${params}`,
            {
              headers: {
                Authorization: `Bearer ${await getToken()}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error(
              `Failed to retrieve email history for ${user.email}.`
            );
          }

          if (response.status < 300) {
            const data = await response.json();

            let emails = uniqBy(
              [...get(cursor, "emails", []), ...(data.emails || [])],
              "id"
            );

            emails = reverse(sortBy(emails, "created"));
            emails = map(emails, (email) => ({ ...email, user }));

            return {
              emails,
              nextPage: data.nextPage,
              userId: user._id,
              userEmail: user.email,
            };
          }
        } catch (err) {
          console.error(err);
        }
      })
    );

    updatedCursors = compact(updatedCursors);

    let updatedEmails = map(updatedCursors, ({ emails }) => emails);
    updatedEmails = flatten(updatedEmails);
    updatedEmails = uniqBy(updatedEmails, "id");
    updatedEmails = reverse(sortBy(updatedEmails, "created"));

    setCursors(updatedCursors);
    setEmails(updatedEmails);
    setIsLoading(false);
  };

  // todo: improve this, it's not very usable or clear when it is gone vs. not
  const canSearch =
    !isEmpty(
      filter(users, (user) => get(user, "integrations.nylas.isConfigured"))
    ) && !isEmpty(filter(contacts, (contact) => contact.email));

  useEffect(() => {
    if (!isSame(contacts, previousContacts) || !isSame(users, previousUsers)) {
      setCursors([]);
      setEmails();
      setEmailIndex(-1);
      setRange(getDefaultRange());
    }
  }, [contacts, users, previousContacts, previousUsers]);

  useEffect(() => {
    if (isNil(emails) && canSearch) {
      search();
      return;
    }

    if (!isNil(emails) && !canSearch) {
      setEmails();
      setCursors([]);
      return;
    }
  }, [canSearch, emails]);

  if (!canSearch || !users || !profile) {
    return null;
  }

  if (!emails) {
    return (
      <Card
        bordered={false}
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
          padding: "48px",
        }}
      >
        <Spin />
      </Card>
    );
  }

  const loadMore = !isEmpty(filter(cursors, (cursor) => cursor.nextPage)) && (
    <div
      style={{
        height: 32,
        lineHeight: "32px",
        marginTop: 12,
        textAlign: "center",
      }}
    >
      <Button disabled={isLoading} ghost onClick={search} type="primary">
        Load More
      </Button>
    </div>
  );

  const onNext =
    emails &&
    emailIndex < emails.length - 1 &&
    (() => setEmailIndex(emailIndex + 1));

  const onPrevious =
    emails && emailIndex > 0 && (() => setEmailIndex(emailIndex - 1));

  return (
    <>
      <FullEmails
        email={emails && emails[emailIndex]}
        onClose={() => setEmailIndex(-1)}
        onLoadMore={
          !onNext &&
          loadMore &&
          (() => {
            setEmailIndex(-1);
            search();
          })
        }
        onLogActivity={onLogActivity}
        onNext={onNext}
        onPrevious={onPrevious}
      />
      <Card bordered={false} bodyStyle={{ paddingTop: "0px" }}>
        <List
          className={classes.list}
          dataSource={emails}
          header={
            <Header
              emails={emails}
              profile={profile}
              range={range}
              refresh={() => {
                setCursors([]);
                setEmails();
              }}
              setRange={setRange}
              users={users}
            />
          }
          itemLayout={"vertical"}
          loading={isLoading}
          loadMore={loadMore}
          locale={{
            emptyText: <Empty description={"No email activity yet"} />,
          }}
          renderItem={(email) => {
            return (
              <List.Item key={email.id}>
                <Email
                  email={email}
                  onClick={() => setEmailIndex(indexOf(emails, email))}
                  onLogActivity={onLogActivity}
                />
              </List.Item>
            );
          }}
        />
      </Card>
    </>
  );
};
