import dayjs from "dayjs";
import isArray from "lodash/isArray.js";
import { Button, DatePicker, Select, InputNumber } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useState } from "react";

const normalizeUnit = (unit) => {
  if (unit === "DAYS") {
    return "DAY";
  }

  if (unit === "MONTHS") {
    return "MONTH";
  }

  if (unit === "YEARS") {
    return "YEAR";
  }

  return unit;
};

export const PastDateFilter = ({ config, defaultValue = null, onFilter }) => {
  const [mode, setMode] = useState(isArray(defaultValue) ? "range" : "canned");

  const [, defaultQuantity, defaultUnit] = ((isArray(defaultValue) || !defaultValue) ? "PAST_15_DAY" : defaultValue).split("_");

  const [quantity, setQuantity] = useState(defaultQuantity);
  const [unit, setUnit] = useState(normalizeUnit(defaultUnit));

  const [range, setRange] = useState(isArray(defaultValue) ? [dayjs.unix(defaultValue[0]), dayjs.unix(defaultValue[1])] : [
    dayjs().subtract(1, "week"), dayjs()
  ]);

  if (mode === "range") {
    return (
      <>
        <DatePicker.RangePicker
          onChange={setRange}
          value={range}
        />
        <div style={{ display: "flex", marginTop: "8px" }}>
          <Button
            onClick={() => {
              setMode("canned");
            }}
            size="small"
            style={{ flexBasis: 0, flexGrow: 1 }}
          >
            Rolling Window
          </Button>
          <Button
            icon={<FilterOutlined />}
            onClick={() => {
              return onFilter({
                dataIndex: config.dataIndex, value: [
                  range[0].unix(),
                  range[1].unix(),
                ]
              });
            }}
            size="small"
            style={{ flexBasis: 0, flexGrow: 1, marginLeft: 8 }}
            type="primary"
          >
            Filter
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        Past
        <InputNumber
          onChange={setQuantity}
          value={quantity}
          size={"small"}
        />
        <Select
          onChange={setUnit}
          options={[
            { value: "DAY", label: "Day(s)" },
            { value: "WEEK", label: "Week(s)" },
            { value: "MONTH", label: "Month(s)" },
            { value: "YEAR", label: "Year(s)" },
          ]}
          optionLabelProp="label"
          value={unit}
          size={"small"}
        />
      </div>
      <div style={{ display: "flex", marginTop: "8px" }}>
        <Button
          onClick={() => {
            setMode("range");
          }}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1 }}
        >
          Period In Time
        </Button>
        <Button
          icon={<FilterOutlined />}
          onClick={() => {
            return onFilter({
              dataIndex: config.dataIndex,
              value: `PAST_${quantity}_${unit}`,
            });
          }}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1, marginLeft: 8 }}
          type="primary"
        >
          Filter
        </Button>
      </div>
    </>
  );
};
